import { FC, useMemo } from "react";
import { SportTypes } from "@finbackoffice/fe-core";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import Img from "components/base/img/Img";
import styles from "./team-icon.module.sass";

interface IProps {
    sportId: number;
    teamId: number | undefined;
}

const TeamIcon: FC<IProps> = ({ sportId, teamId }) => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const fallbackImg = useMemo(() => {
        let icon: string;

        switch (sportId) {
            case SportTypes.SOCCER:
            case SportTypes.BASKETBALL:
            case SportTypes.BASEBALL:
            case SportTypes.ICE_HOCKEY:
            case SportTypes.HANDBALL:
            case SportTypes.RUGBY:
            case SportTypes.AUSSIE_RULES:
            case SportTypes.AMERICAN_FOOTBALL:
            case SportTypes.VOLLEYBALL:
            case SportTypes.FIELD_HOCKEY:
            case SportTypes.COUNTER_STRIKE:
            case SportTypes.DOTA2:
            case SportTypes.CALL_OF_DUTY:
            case SportTypes.OVERWATCH:
                icon = sportId.toString();
                break;
            default:
                icon = "";
                break;
        }

        return icon ? `${ASSETS_URL}/common/team-icons/defaults/${icon}.webp` : null;
    }, [sportId, ASSETS_URL]);

    return fallbackImg ? (
        <Img
            source={`${ASSETS_URL}/common/team-icons/${sportId}/${teamId}.webp`}
            wrapperClassName={styles.teamIcon}
            fallbackSrc={fallbackImg}
            //loader={<CircleLoader />}
            width={0}
            height={0}
            style={{ width: "auto", height: "100%" }}
            alt="Team icon"
        />
    ) : null;
};

export default TeamIcon;
