import { IEventFeedResponse } from "@finbackoffice/websocket-client";
import classnames from "classnames";
import { FC } from "react";
import { EventStatus } from "@finbackoffice/enums";
import TeamIcon from "components/pages/sport/base/team-icon/TeamIcon";
import { useDate, DEFAULT_DATE_FORMAT } from "hooks";
import styles from "../home-tournament-game-scoreboard.module.sass";

type Props = {
    game: IEventFeedResponse;
};

const HomeTournamentGameScoreboardSoccer: FC<Props> = ({ game }) => {
    const { formatDate } = useDate();
    const isLive = game.status === EventStatus.Live;

    return (
        <div className={classnames(styles.soccer, isLive && styles.liveSoccer)}>
            <div className={styles.soccerTeam}>
                <TeamIcon sportId={game.sport_id} teamId={game.home_team?.id} />
                <span>{game.home_team?.name}</span>
            </div>

            {isLive ? (
                <div className={styles.soccerScore}>
                    <span>
                        <span>{game.scoreboard?.home_score}</span>:
                        <span>{game.scoreboard?.away_score}</span>
                    </span>
                    {game.match_status}
                </div>
            ) : (
                <div className={styles.soccerScore}>
                    {game.scheduled_at && (
                        <>
                            <span>
                                {formatDate(new Date(game.scheduled_at), DEFAULT_DATE_FORMAT)}
                            </span>
                            {formatDate(new Date(game.scheduled_at), "HH:mm")}
                        </>
                    )}
                </div>
            )}
            <div className={styles.soccerTeam}>
                <TeamIcon sportId={game.sport_id} teamId={game.away_team?.id} />
                <span>{game.away_team?.name}</span>
            </div>
        </div>
    );
};

export default HomeTournamentGameScoreboardSoccer;
