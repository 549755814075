import { CasinoGameTag } from "@finbackoffice/enums";
import { CasinoGameTypeEnum } from "@finbackoffice/fe-core";
import { FC, createContext, useMemo } from "react";
import { ICasinoDataState } from "@finbackoffice/site-core";
import { useCasinoGames } from "hooks";
import Compact from "./layouts/compact/Compact";

type IProps = {
    tag?: CasinoGameTag;
};

type IHomeLiveCasinoTablesContext = IProps & {
    casinoGamesState: ICasinoDataState;
};

export const HomeLiveCasinoTablesContext = createContext<IHomeLiveCasinoTablesContext>(null as any);

const HomeLiveCasinoTables: FC<IProps> = ({ tag }) => {
    const gameTypesMemo = useMemo(() => [CasinoGameTypeEnum.LIVE_CASINO_TABLE], []);
    const { casinoGamesState } = useCasinoGames(gameTypesMemo, tag ?? "", "All");

    const value = useMemo(
        () => ({
            casinoGamesState,
            tag,
        }),
        [casinoGamesState, tag],
    );

    return (
        <HomeLiveCasinoTablesContext.Provider value={value}>
            <Compact />
        </HomeLiveCasinoTablesContext.Provider>
    );
};

export default HomeLiveCasinoTables;
