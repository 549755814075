import { FC, createContext, useMemo } from "react";
import { CasinoGameTag } from "@finbackoffice/enums";
import { ICasinoDataState, CasinoScopesMap } from "@finbackoffice/site-core";
import { useCasinoGames } from "hooks";
import Compact from "./layouts/compact/Compact";

type IProps = {
    tag?: CasinoGameTag;
};

type IHomeSlotsContext = IProps & {
    casinoGamesState: ICasinoDataState;
};

export const HomeSlotsContext = createContext<IHomeSlotsContext>(null as any);

const HomeSlots: FC<IProps> = ({ tag }) => {
    const { casinoGamesState } = useCasinoGames(CasinoScopesMap.slots, tag ?? "", "All");

    const value = useMemo(
        () => ({
            casinoGamesState,
            tag,
        }),
        [casinoGamesState, tag],
    );

    return (
        <HomeSlotsContext.Provider value={value}>
            <Compact />
        </HomeSlotsContext.Provider>
    );
};

export default HomeSlots;
