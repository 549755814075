import Link from "next/link";
import { FC, useContext, useMemo } from "react";
import { Settings } from "react-slick";
import { TranslationScopes } from "@finbackoffice/enums";
import { useRuntimeConfig, useTranslation } from "@finbackoffice/site-core";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Loading from "components/base/loading/Loading";
import SlickSlider from "components/base/slick-slider/SlickSlider";
import Translate from "components/base/translate/Translate";
import CasinoGameItem from "components/pages/casino/game-item/CasinoGameItem";
import { Svg } from "components/base/svg/Svg";
import { RouteNames } from "utils/constants";
import { HomeLiveCasinoContext } from "../../HomeLiveCasino";
import styles from "./compact.module.sass";

const Compact: FC = () => {
    const { t } = useTranslation(TranslationScopes.Casino);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { casinoGamesState, tag } = useContext(HomeLiveCasinoContext);

    const sliderSettings: Settings = useMemo(
        () => ({
            dots: false,
            infinite: false,
            variableWidth: true,
            speed: 1000,
            slidesToShow: 8,
            slidesToScroll: 2,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1432,
                    settings: {
                        slidesToShow: 7,
                    },
                },
                {
                    breakpoint: 1255,
                    settings: {
                        slidesToShow: 6,
                    },
                },
                {
                    breakpoint: 1080,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 726,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 552,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        }),
        [],
    );

    if (casinoGamesState.loading) {
        return (
            <div className={styles.homePageItem}>
                <Loading />
            </div>
        );
    }

    return casinoGamesState.games.length !== 0 ? (
        <FadeInAnimation>
            <div className={styles.homePageItem}>
                <div className={styles.header}>
                    <h2 className={styles.headerSport}>
                        <Svg
                            src="/common/desktop/base-icons/live-casino-icon.svg"
                            wrapper="span"
                            className="svg-sport-icon"
                        />
                        <span>
                            <Translate
                                tid="home_live_casino_title"
                                namespace={TranslationScopes.Home}
                            />
                        </span>
                    </h2>
                    <Link
                        href={{
                            pathname: `/${RouteNames.CASINO}/${RouteNames.LIVE_CASINO}`,
                        }}>
                        <Translate tid="home_show_all" namespace={TranslationScopes.Home} />
                    </Link>
                </div>
                <div className={styles.headText}>
                    <h2>
                        <Translate
                            tid="home_live_casino_head"
                            namespace={TranslationScopes.Home}
                            replace={{
                                app_name: COMMON_SITE_CONFIGS.appName,
                                tag: tag ? t(`casino_${tag?.toLowerCase()}`) : "",
                            }}
                        />
                    </h2>
                </div>
                <div className={styles.liveCasinoGames}>
                    <div>
                        <SlickSlider settings={sliderSettings}>
                            {casinoGamesState.games.map((game) => (
                                <CasinoGameItem key={game.id} game={game} hidePlayButtons />
                            ))}
                        </SlickSlider>
                    </div>
                </div>
            </div>
        </FadeInAnimation>
    ) : null;
};

export default Compact;
