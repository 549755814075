import { GetServerSideProps, GetServerSidePropsContext, Page } from "next";
import { SiteContentScopes, PopupTags, TranslationScopes } from "@finbackoffice/enums";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Seo from "components/base/seo/Seo";
import { getCommonLayout } from "components/layout/common/CommonLayout";
import Home from "components/pages/home/Home";
import { usePrematchSports } from "hooks";
import {
    CommonSsrProps,
    clientBFFSingleton,
    createGetServerSideProps,
    getServerData,
    getServerRuntimeConfig,
    withSsrBanners,
    withSsrCasinoFavorites,
} from "services/ssr";
import { getCurrentLocale } from "utils/helpers";

export const getServerSideProps: GetServerSideProps = createGetServerSideProps<CommonSsrProps>(
    async (context: GetServerSidePropsContext) => {
        const runtimeConfig = await getServerRuntimeConfig();
        const client = clientBFFSingleton.getInstance(context.req.cookies.jwt);
        const locale = getCurrentLocale(runtimeConfig, context.locale);

        const bannerScope = [SiteContentScopes.Homepage];

        const translationScope = [
            TranslationScopes.Home,
            TranslationScopes.Casino,
            TranslationScopes.CasinoLiveCasino,
            TranslationScopes.CasinoSlots,
        ];

        const [{ translations, configs, profile }, banners, casinoFavoriteGames] =
            await Promise.all([
                getServerData(runtimeConfig, translationScope, context, client),
                withSsrBanners(client, locale, bannerScope),
                context.req.cookies.jwt ? withSsrCasinoFavorites(client) : undefined,
            ]);

        return {
            props: {
                configs,
                runtimeConfig,
                jwt: context.req.cookies.jwt || "",
                profile,
                locale,
                translations,
                banners: banners.items,
                popupScope: [PopupTags.Allpages],
                casinoFavoriteGames: casinoFavoriteGames?.items || null,
            },
        };
    },
);

const IndexPage: Page = () => {
    usePrematchSports();

    return (
        <ErrorBoundary name={Home.name}>
            <Seo page={TranslationScopes.Home} />
            <Home />
        </ErrorBoundary>
    );
};

IndexPage.getLayout = getCommonLayout;

export default IndexPage;
