import classnames from "classnames";
import { ReactNode, FC, PropsWithChildren, forwardRef, RefAttributes } from "react";
import Loading from "components/base/loading/Loading";
import styles from "./casino-widget-container.module.sass";

type Props = {
    renderHeader: ReactNode;
    variant?: string;
    expand?: boolean;
    height?: number;
    loading?: boolean;
};

const CasinoWidgetContainer: FC<Props & PropsWithChildren & RefAttributes<HTMLDivElement>> =
    forwardRef(({ renderHeader, children, variant, expand, height, loading }, ref) => {
        return (
            <div
                id={variant}
                style={height ? { minHeight: height } : undefined}
                className={classnames(
                    styles.casinoCompactLayoutItem,
                    variant && styles[variant],
                    expand && styles.expand,
                )}>
                <div>
                    <div
                        className={styles.expandedContent}
                        ref={ref}
                        style={height ? { height } : undefined}>
                        <div className={styles.casinoCompactLayoutItemHeader}>{renderHeader}</div>
                        <div className={styles.casinoCompactLayoutItemContent}>
                            {children}
                            {loading && <Loading />}
                        </div>
                    </div>
                </div>
            </div>
        );
    });

export default CasinoWidgetContainer;
