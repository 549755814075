import { FC, useCallback, useContext, useMemo } from "react";
import { Settings } from "react-slick";
import { BannersContext, FeedContext, useSiteConfig } from "@finbackoffice/site-core";
import {
    HomePageSectionsEnum,
    ILayoutsHomePageSection,
    ISiteConfigLayouts,
} from "@finbackoffice/fe-core";
import { SiteContentScopes } from "@finbackoffice/enums";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import BannersSlider from "../../base/banners-slider/BannersSlider";
import Reels from "../casino/base/reels/Reels";
import HomeTournamentItem from "./tournament/HomeTournamentItem";
import HomeSlots from "./slots/HomeSlots";
import HomeLiveCasino from "./live-casino/HomeLiveCasino";
import styles from "./home.module.sass";
import HomeLiveCasinoTables from "./live-casino-tables/HomeLiveCasinoTables";
import HomeFAQ from "./faq/HomeFAQ";

const Home: FC = () => {
    const homePageConfig = useSiteConfig<ISiteConfigLayouts>("layouts").homePage;
    const { banners } = useContext(BannersContext);
    const { prematchTopLeagues } = useContext(FeedContext);
    const hasTopLeagues = prematchTopLeagues.length > 0;

    const bannerSettings: Settings = useMemo(
        () => ({
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 440,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: false,
                    },
                },
            ],
        }),
        [],
    );

    const renderSection = useCallback(
        (section: ILayoutsHomePageSection, index: number) => {
            if (section.enable) {
                switch (section.name) {
                    case HomePageSectionsEnum.Banners:
                        return (
                            <BannersSlider
                                key={index}
                                banners={banners}
                                sliderSettings={bannerSettings}
                                wrapperClassName={styles.homeBannerContainer}
                                imgProps={{
                                    width: 400,
                                    height: 270,
                                }}
                            />
                        );
                    case HomePageSectionsEnum.Slots:
                        if (section.tag) {
                            return <HomeSlots key={index} tag={section.tag} />;
                        }
                        break;
                    case HomePageSectionsEnum.LiveCasino:
                        return <HomeLiveCasino key={index} tag={section.tag} />;
                    case HomePageSectionsEnum.LiveCasinoTables:
                        return <HomeLiveCasinoTables key={index} tag={section.tag} />;
                    case HomePageSectionsEnum.Reels:
                        return <Reels key={index} scope={SiteContentScopes.Homepage} />;
                    case HomePageSectionsEnum.Sport:
                        if (section.top_leagues_index && hasTopLeagues) {
                            return section.top_leagues_index.map((leagueIndex) => {
                                const tournament = prematchTopLeagues[leagueIndex - 1];
                                return tournament ? (
                                    <HomeTournamentItem
                                        key={tournament.id}
                                        tournament={tournament}
                                    />
                                ) : null;
                            });
                        }
                        break;
                    default:
                        return null;
                }
            }
            return null;
        },
        [bannerSettings, banners, hasTopLeagues, prematchTopLeagues],
    );

    return (
        <>
            <FadeInAnimation>
                <div className={styles.middleAreaHomePage}>
                    {homePageConfig.widgets.map(renderSection)}
                </div>
            </FadeInAnimation>
            {homePageConfig.enableFAQ && <HomeFAQ />}
        </>
    );
};

export default Home;
