import { IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import { FC, SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { BetSlipContext, ConfigContext, usePrevious } from "@finbackoffice/site-core";
import { OddFormatter, getBetStatus } from "@finbackoffice/fe-core";
import { MarketStatus, MarketOutcomeStatus } from "@finbackoffice/enums";
import { useGetCurrentFeedType, Feed } from "hooks";
import { Svg } from "components/base/svg/Svg";
import styles from "./outcome.module.sass";

interface IProps {
    outcome?: IMarketOutcomeFeedResponse | null;
    onOutcomeClick?: (outcome: IMarketOutcomeFeedResponse) => void;
    marketStatus?: MarketStatus;
    variant?: string;
}

const Outcome: FC<IProps> = ({ outcome, onOutcomeClick, marketStatus, variant }) => {
    const feed: Feed = useGetCurrentFeedType();
    const isResultsFeed = feed === Feed.results;
    const { isOutcomeSelected } = useContext(BetSlipContext);
    const { siteCurrentOddFormat } = useContext(ConfigContext);
    const [arrowClass, setArrowClass] = useState("");
    const prevOdds: string = usePrevious(outcome?.odds) ?? "";
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const isOutcomeDisabled =
        marketStatus === MarketStatus.OnHold ||
        (outcome && outcome.status === MarketOutcomeStatus.NotActive) ||
        !outcome;
    const selected = useMemo(
        () => (outcome ? isOutcomeSelected(outcome.id) : false),
        [isOutcomeSelected, outcome],
    );

    const onClickHandler = (event: SyntheticEvent) => {
        if (!isOutcomeDisabled) {
            event.stopPropagation();
            event.preventDefault();

            onOutcomeClick?.(outcome);
        }
    };

    useEffect(() => {
        if (prevOdds && outcome) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (parseFloat(prevOdds) < parseFloat(outcome.odds)) {
                setArrowClass("increased");
            } else if (parseFloat(prevOdds) > parseFloat(outcome.odds)) {
                setArrowClass("decreased");
            }

            timeoutRef.current = setTimeout(() => {
                setArrowClass("");
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outcome?.odds]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const renderContent = useMemo(() => {
        if (!outcome) return;

        if (isResultsFeed && marketStatus) {
            const iconName = getBetStatus(marketStatus, outcome)?.toLowerCase().split("_").join("");
            const iconSrc = `/common/desktop/base-icons/status-${iconName}.svg`;

            return (
                <span className={styles.resultsOutcomeItemValue}>
                    <Svg
                        src={iconSrc}
                        wrapper="span"
                        className={classnames("svg-icon", iconName)}
                    />
                </span>
            );
        }

        return (
            <span className={styles.outcomeItemValue}>
                {OddFormatter.format(parseFloat(outcome.odds), siteCurrentOddFormat)}
            </span>
        );
    }, [isResultsFeed, marketStatus, outcome, siteCurrentOddFormat]);

    return (
        <div
            className={classnames(
                styles.outcomeItem,
                styles[arrowClass],
                variant && styles[variant],
                isOutcomeDisabled && styles.disabled,
                selected && styles.active,
            )}
            data-testid={`outcome-${isOutcomeDisabled ? "disabled" : "enabled"}`}
            onClick={isResultsFeed ? undefined : onClickHandler}>
            {outcome ? (
                <>
                    <span>{outcome.name}</span>
                    {renderContent}
                </>
            ) : (
                "- -"
            )}
        </div>
    );
};
export default Outcome;
